import { faFileDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Button } from "reactstrap";
import { employeeSafetyCertificationsApi } from "../../services/employeeSafetyCertificationsService";

export const useDocumentPreviewModal = (props = {}) => {
  const { onFetchDocumentStart = () => {}, onFetchDocumentEnd = () => {} } =
    props;

  const emailRecipientModalInit = {
    open: false,
    title: "",
    defaultContent: "",
    onClose: () => {},
    onSubmit: async () => {},
  };

  const [emailRecipientsModal, setEmailRecipientsModalModal] = useState(
    emailRecipientModalInit
  );

  const [documentPreviewModal, setDocumentPreviewModal] = useState({
    isOpen: false,
    file: null,
    onClose: null,
  });

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const downloadFile = async (id) => {
    try {
      await employeeSafetyCertificationsApi.getSignedFile(id);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Download Certificate",
        body: "Employee Certificate not found or is not available to download",
        onClose: () => setInformationModal({ isOpen: false }),
      });
    }
  };

  const openSendEmailModal = (employee, certificate) => {
    setEmailRecipientsModalModal({
      open: true,
      title: "Send Certifications by Email",
      defaultContent: `<p><strong>${employee.firstName} ${employee.lastName} Safety Certifications</strong><p><p>Find safety docs attached to this email</p>`,
      onClose: () => setEmailRecipientsModalModal(emailRecipientModalInit),
      onSubmit: (listEmail, content) => {
        employeeSafetyCertificationsApi
          .email({
            employeeId: employee.id,
            content,
            recipients: listEmail,
            ...(certificate && { certificationIds: [certificate.id] }),
          })
          .then(() => {
            setEmailRecipientsModalModal(emailRecipientModalInit);
            setInformationModal({
              isOpen: true,
              title: "Send Certificate by Email",
              body: "Email sent successfully",
              onClose: () => setInformationModal({ isOpen: false }),
            });
          })
          .catch(() => {
            setInformationModal({
              isOpen: true,
              title: "Send Certificate by Email",
              body: "There was an error sending the email.",
              onClose: () => setInformationModal({ isOpen: false }),
            });
          });
      },
    });
  };

  const openDocumentPreview = async (certification, employee) => {
    try {
      if (!certification.certLink) {
        setInformationModal({
          isOpen: true,
          title: `Document Preview`,
          body: `Certification preview is not available`,
          onClose: () => {
            setInformationModal({ isOpen: false, title: "", body: "" });
          },
        });
      }

      const isPDF = certification.certLink.endsWith(".pdf");
      onFetchDocumentStart(certification);

      const file = await employeeSafetyCertificationsApi.getSignedFile(
        certification.id,
        { type: "blob" }
      );
      onFetchDocumentEnd(certification);

      const previewActionsContent = (
        <div
          className={`d-flex mt-3 justify-content-end ${isPDF ? "mx-5" : ""}`}
        >
          <Button
            className="rounded mr-2"
            color="success"
            size="sm"
            onClick={() => downloadFile(certification.id)}
            disabled={certification.certLink ? false : true}
          >
            Download{" "}
            <FontAwesomeIcon className="ml-1" size="md" icon={faFileDownload} />
          </Button>
          <Button
            className="rounded"
            color="success"
            size="sm"
            onClick={() => openSendEmailModal(employee, certification)}
            disabled={certification.certLink ? false : true}
          >
            Email{" "}
            <FontAwesomeIcon className="ml-1" size="md" icon={faEnvelope} />
          </Button>
        </div>
      );

      if (isPDF) {
        setDocumentPreviewModal({
          isOpen: true,
          file: window.URL.createObjectURL(file),
          onClose: () => setDocumentPreviewModal({ isOpen: false }),
          additionalContent: {
            placement: "BODY_TOP",
            content: previewActionsContent,
          },
        });
      } else {
        setInformationModal({
          isOpen: true,
          title: "Document Preview",
          rawBody: true,
          body: (
            <div className="d-flex flex-column text-center">
              {previewActionsContent}
              <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
                {({ zoomIn, zoomOut }) => (
                  <React.Fragment>
                    <div
                      style={{ position: "absolute", zIndex: 100 }}
                      className="tools mt-2"
                    >
                      <Button
                        className="rounded mx-2 btn-light border"
                        onClick={() => zoomOut()}
                      >
                        -
                      </Button>
                      <Button
                        className="rounded btn-light border"
                        onClick={() => zoomIn()}
                      >
                        +
                      </Button>
                    </div>
                    <TransformComponent>
                      <img
                        className="img-fluid rounded"
                        src={window.URL.createObjectURL(file)}
                        alt="Document"
                      />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          ),
          onClose: () => setInformationModal({ isOpen: false }),
        });
      }
    } catch (error) {
      onFetchDocumentEnd(certification);
      setInformationModal({
        isOpen: true,
        title: "Preview Certificate",
        body: "Employee Certificate not found or is not available for preview",
        onClose: () => setInformationModal({ isOpen: false }),
      });
    } finally {
      onFetchDocumentEnd(certification);
    }
  };

  return {
    documentPreviewModal,
    openDocumentPreview,
    openSendEmailModal,
    emailRecipientsModal,
    informationModal,
    setInformationModal,
  };
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../helpers/reportsHelper";
import Loader from "../../../components/Loader";
import InspectionDetails from "../../../components/admin/workOrderDetails/inspections/InspectionDetails";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import { employeeSafetyCertificationsApi } from "../../../services/employeeSafetyCertificationsService";
import { useAuth } from "../../../providers/authProvider";
import DocumentPreviewModal from "../../../components/admin/DocumentPreviewModal";
import InformationModal from "../../../components/InformationModal";
import { useDocumentPreviewModal } from "../../../helpers/hooks/useDocumentPreviewModal";
import EmailRecipientsModal from "../../../components/EmailRecipientsModal";
import PaginationSelector from "../../../components/admin/PaginationSelector";

const REPORT_NAME = "SafetyCertificationsReport_";

const columnLabel = [
  "Employee Code",
  "Employee Name",
  "Cert. Description",
  "Cert. Issued",
  "Cert. Expiration",
  "Cert. Link",
];

const COLUMNS = columnLabel.reduce((acc, label) => {
  acc[label] = {
    type: "string",
  };

  return acc;
}, {});

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: columnLabel.map((label) => ({ uniqueName: label })),
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Safety Certifications Report",
    },
  },
};

const TYPE_WEEKLY = "TYPE_WEEKLY";
const TYPE_PAGINATED = "TYPE_PAGINATED";

const PAGINATION_ACTIONS = {
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
};

const SafetyCertificationsReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const [loadingCertificate, setLoadingCertificate] = useState({
    loading: false,
    certificate: null,
  });

  const [data, setData] = useState(null);

  const getFilename = () =>
    reportsHelper.getFileName(monday, true, REPORT_NAME);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_PAGINATED,
    label: "Pagination",
  });

  const [paginatedData, setPaginatedData] = useState({
    currentPage: 1,
    pageSize: 500,
    pageCount: 0,
    totalCount: 0,
  });

  const {
    openDocumentPreview,
    documentPreviewModal,
    informationModal,
    emailRecipientsModal,
  } = useDocumentPreviewModal({
    onFetchDocumentStart: (cert) =>
      setLoadingCertificate({ loading: true, certificate: cert.id }),
    onFetchDocumentEnd: () =>
      setLoadingCertificate({ loading: false, certificate: null }),
  });

  const [loading, setLoading] = useState(false);
  const [inspectionDetailModal, setInspectionDetailModal] = useState(undefined);

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const isExpired = (certExpDate) => {
    const today = new Date();
    const expirationDate = new Date(certExpDate);

    return today > expirationDate;
  };

  const isToExpire = (certExpDate) => {
    const today = new Date();
    const expirationDate = new Date(certExpDate);
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 60;
  };

  const addButtonToCells = useCallback(
    (cell, entry) => {
      if (
        entry.type !== "header" &&
        entry.measure?.uniqueName === "Cert. Link" &&
        entry.rowIndex > 0
      ) {
        const itemFromData = data?.[entry.rowIndex - 1];

        const isDisabled =
          !itemFromData || !itemFromData.certLink || loadingCertificate.loading;

        const isLoading =
          loadingCertificate.loading &&
          loadingCertificate.certificate === itemFromData?.id;

        cell.text = `<input 
            class="btn btn-info text-white rounded text-center col-12 ${
              isDisabled || isLoading ? "disabled" : "cursor-pointer"
            }"
            type="button"
            ${isDisabled || isLoading ? "disabled" : ""}
            value="${isLoading ? "Loading..." : "View Certificate"}"
          >`;
        cell.style["z-index"] = 1;
      }

      if (
        entry.type !== "header" &&
        entry.measure?.uniqueName === "Cert. Expiration" &&
        entry.rowIndex > 0
      ) {
        const itemFromData = data?.[entry.rowIndex - 1];
        const expirationDate = itemFromData["Cert. Expiration"];

        if (isToExpire(expirationDate) && !isExpired(expirationDate)) {
          cell.addClass("bg-six");
          cell.addClass("text-white");
        }

        if (isExpired(expirationDate)) {
          cell.addClass("bg-danger");
          cell.addClass("text-white");
        }
      }
    },

    [loadingCertificate, data]
  );

  const onButtonClick = useCallback(
    (cell) => {
      if (cell.hierarchy && cell.hierarchy.caption === "Cert. Link") {
        const certificate = data[cell.rowIndex - 1];
        if (!certificate) return;

        openDocumentPreview(certificate, certificate?.employee);
      }
    },
    [data, openDocumentPreview]
  );

  const fetchData = useCallback(() => {
    setLoading(true);

    const { value } = periodType;
    const condition = {};

    if (value === TYPE_PAGINATED) {
      condition.page = paginatedData.currentPage - 1;
      condition.pageSize = paginatedData.pageSize;
    } else {
      condition.startDate =
        value === TYPE_WEEKLY
          ? moment(monday).format("YYYY-MM-DD")
          : moment(customStartDate).format("YYYY-MM-DD");

      condition.endDate =
        value === TYPE_WEEKLY
          ? moment(monday).endOf("isoWeek").format("YYYY-MM-DD")
          : moment(customEndDate).format("YYYY-MM-DD");

      condition.pageSize = paginatedData.totalCount;
    }

    condition.jobSourceId = authContext.currentUser.jobSourceId;

    employeeSafetyCertificationsApi
      .getAll(condition)
      .then((result) => {
        if (value === TYPE_PAGINATED) {
          setPaginatedData((prev) => ({
            ...prev,
            totalCount: result.count,
            pageCount: result.totalPages,
          }));
        }

        setData(
          result.data.map((cert) => ({
            ...cert,
            "Employee Code": cert.employee.usrDfnId,
            "Employee Name": `${cert.employee.firstName} ${cert.employee.lastName}`,
            "Cert. Description": cert.certDescription,
            "Cert. Issued": utilsHelper.formatUTCDate(
              cert.certEmission,
              "MM/DD/YYYY"
            ),
            "Cert. Expiration": utilsHelper.formatUTCDate(
              cert.certExpiration,
              "MM/DD/YYYY"
            ),
            "Cert. Link": cert.certLink,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    periodType,
    authContext.currentUser.jobSourceId,
    paginatedData.currentPage,
    paginatedData.pageSize,
    paginatedData.totalCount,
    monday,
    customStartDate,
    customEndDate,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (pivot?.current?.flexmonster) {
      pivot.current.flexmonster.updateData({
        data: [COLUMNS, ...(data || [])],
      });
      if (data?.length) {
        // Clear all existing event listeners
        pivot.current.flexmonster.off("cellclick");

        // Add cell customization and single event listener
        pivot.current.flexmonster.customizeCell(addButtonToCells);
        pivot.current.flexmonster.on("cellclick", (cell) => {
          if (!loadingCertificate.loading) {
            onButtonClick(cell);
          }
        });
      }
    }
  }, [
    pivot,
    data,
    addButtonToCells,
    onButtonClick,
    loadingCertificate.loading,
  ]);

  const handlePagination = (value, action) => {
    const isPageSizeAction = action === PAGINATION_ACTIONS.PAGE_SIZE_CHANGE;
    setPaginatedData((prev) => ({
      ...prev,
      [isPageSizeAction ? "pageSize" : "currentPage"]: value,
      ...(isPageSizeAction ? { currentPage: 1 } : {}),
    }));
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
                additionalOptions={[
                  {
                    label: "Pagination",
                    value: TYPE_PAGINATED,
                  },
                ]}
              />
              {periodType.value === TYPE_PAGINATED ? (
                <PaginationSelector
                  {...paginatedData}
                  currentPage={paginatedData.currentPage - 1}
                  onPageSizeChange={(val) =>
                    handlePagination(val, PAGINATION_ACTIONS.PAGE_SIZE_CHANGE)
                  }
                  onPageChange={(val) =>
                    handlePagination(val, PAGINATION_ACTIONS.PAGE_CHANGE)
                  }
                />
              ) : periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                  disableFutureWeeks={false}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
            {inspectionDetailModal ? (
              <InspectionDetails
                inspectionId={inspectionDetailModal}
                onClose={() => {
                  setInspectionDetailModal(undefined);
                  fetchData();
                }}
              />
            ) : null}
          </Card>
        </Col>
      </Row>
      {emailRecipientsModal.open ? (
        <EmailRecipientsModal {...emailRecipientsModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={informationModal.onClose}
        />
      ) : documentPreviewModal.isOpen ? (
        <DocumentPreviewModal {...documentPreviewModal} pageWidth={800} />
      ) : null}
    </Container>
  );
};

export default SafetyCertificationsReport;
